// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-jobs-jsx": () => import("./../../../src/pages/jobs.jsx" /* webpackChunkName: "component---src-pages-jobs-jsx" */),
  "component---src-pages-projekte-jsx": () => import("./../../../src/pages/projekte.jsx" /* webpackChunkName: "component---src-pages-projekte-jsx" */),
  "component---src-templates-page-page-jsx": () => import("./../../../src/templates/page/page.jsx" /* webpackChunkName: "component---src-templates-page-page-jsx" */),
  "component---src-templates-post-post-jsx": () => import("./../../../src/templates/post/post.jsx" /* webpackChunkName: "component---src-templates-post-post-jsx" */),
  "component---src-templates-project-project-jsx": () => import("./../../../src/templates/project/project.jsx" /* webpackChunkName: "component---src-templates-project-project-jsx" */)
}

